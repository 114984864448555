import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContactForm from "../../components/contactForm"
import Hero from "../../components/hero"
import Services from "../../components/services2"
import Content from "../../components/content"

/**
 * GraphQL Query to retrieve page data
 *
 * @return {Object}
 */
export const ServicesPageQuery = graphql`
  {
    site {
      siteMetadata {
        email
        phone
      }
    }
    hero: file(relativePath: { eq: "pages/about/header-home-erick.jpg" }) {
      sharp: childImageSharp {
        fluid(
          quality: 100
          maxWidth: 1920
          duotone: { highlight: "#15181f", shadow: "#15181f", opacity: 55 }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

/**
 * Services Page Component
 * This component is used to generate the Services page
 *
 * @param {Object} props
 */
const CambiodeNombrePage = ({ data }) => {
  return (
    <Layout isSecondary={true}>
      <SEO title="Cambio de nombre" description="" />

      <Hero
        type="simple"
        tagline="Estos son los principales servicios jurídicos que le ofrecemos"
        title="Cambio de Nombre"
        background={data.hero.sharp.fluid}
      />

      <Content
        tagline=""
        title=""
      >
        <p>
        El fundamento legal lo encontramos en el libro I   artículo  6 del Código Civil de Guatemala.
        </p>        
        <p>
        El procedimiento para realizar el cambio de nombre está regulado en los artículos 438 y 439
        del Código Procesal Civil y Mercantil de Guatemala, que indica que la persona que por cualquier
        motivo desee cambiar su nombre de acuerdo con lo establecido en el Código Civil, lo solicitará
        por escrito al juez de Primera Instancia de su domicilio, expresando los motivos que tenga para
        hacerlo y el nombre completo que quiera adoptar.
          </p>       
      </Content>

      <Services />

      <ContactForm
        email={data.site.siteMetadata.email}
        phone={data.site.siteMetadata.phone}
      />
    </Layout>
  )
}

export default CambiodeNombrePage

